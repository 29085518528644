var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PaymentCardBase',{attrs:{"title":_vm.title,"active":_vm.active},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('splide',{attrs:{"options":_vm.splideOption},on:{"splide:move":_vm.onMove}},_vm._l((_vm.wallets),function(wallet,walletIndex){return _c('splide-slide',{key:walletIndex},[_c('WalletCard',{attrs:{"size":"base","title-prefix":wallet.currency.symbol,"title":wallet.balance,"subtitle":_vm.$t(
              'components.riderDetailsManagement.diveIntoTab.paymentMethod.wallet.currentBalance'
            ),"text-position":"center"}})],1)}),1),_c('section',{staticClass:"h-8 text-center border"},[(_vm.currentWallet.default)?_c('span',{staticClass:"text-sm text-center text-gray-500",domProps:{"textContent":_vm._s(
          _vm.$t(
            'components.riderDetailsManagement.diveIntoTab.paymentMethod.wallet.default'
          )
        )}}):_vm._e()]),_c('section',[_c('div',{staticClass:"grid grid-cols-2 border-b divide-x divide-y"},_vm._l((_vm.currentWalletData),function(walletData,walletDataIndex){return _c('div',{key:walletDataIndex,staticClass:"flex flex-col items-center justify-center px-2 py-3",class:walletDataIndex === 0 ? 'border-t border-l' : ''},[_c('span',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(walletData.label)+" ")]),_c('span',{staticClass:"font-medium text-gray-700"},[_vm._v(" "+_vm._s(walletData.value)+" ")])])}),0)]),_c('section',{staticClass:"flex flex-col items-center justify-center w-full py-4 mb-4 lg:flex-row lg:gap-x-3 gap-y-3 lg:gap-y-0"},[_c('AnchorButton',{attrs:{"text":_vm.$t(
            'components.riderDetailsManagement.diveIntoTab.paymentMethod.action.deductBalance'
          ),"width":"10rem","variant":"warning","isLoading":false},on:{"click":function($event){return _vm.$emit('deductBalance')}}}),_c('AnchorButton',{attrs:{"text":_vm.$t(
            'components.riderDetailsManagement.diveIntoTab.paymentMethod.action.addBalance'
          ),"width":"10rem","variant":"green","isLoading":false},on:{"click":function($event){return _vm.$emit('addBalance')}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }